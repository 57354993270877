import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import "./App.css";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";

const getStaticProps = async (accessToken) => {
  const myHeaders = new Headers({ Authorization: `Bearer ${accessToken}` });
  const data = await fetch(
    "https://europe-west2-progit-playground.cloudfunctions.net/progitInfo",
    {
      method: "GET",
      headers: myHeaders,
    }
  ).then((res) => res.json());

  return {
    blockMap: data,
  };
};

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [blockMap, setBlockMap] = useState({});

  useEffect(() => {
    if (accessToken) {
      getStaticProps(accessToken).then((props) => {
        setBlockMap(props.blockMap);
      });
    }
  }, [accessToken]);

  return (
    <div className="App">
      {!accessToken ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GoogleLogin
            clientId="835186843231-oqhq19a1p3s4a4u9q7dbnchtpkatch9p.apps.googleusercontent.com"
            autoLoad
            isSignedIn={true}
            fetchBasicProfile
            onSuccess={(response) => {
              const { email } = response.profileObj;
              const domain = email.split("@")[1];
              if (domain === "progit.no") {
                const { id_token } = response.tokenObj;
                setAccessToken(id_token);
              }
            }}
            onFailure={() => {}}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      ) : (
        <div>
          <NotionRenderer blockMap={blockMap} fullPage />
        </div>
      )}
    </div>
  );
}

export default App;
